import "./carasoule.css";
import "./services.css";

import botox from "../assets/Botox.jpeg";
import Carbon_facial from "../assets/Carbon_facial.jpeg";
import Derma_pen from "../assets/Derma_pen.jpeg";
import Fillers from "../assets/Fillers.jpeg";
import Gfc_face from "../assets/Gfc_face.jpeg";
import Gfc_hair from "../assets/Gfc_hair.jpeg";
import Hifu from "../assets/Hifu.jpeg";
import Laser_ from "../assets/Laser_.jpeg";
import hydra_facial from "../assets/hydrafacial.jpg";
import Prp_face from "../assets/Prp_face.jpeg";
import Prp_hair from "../assets/Prp_hair.jpeg";
import Vampire_facial from "../assets/Vampire_facial.jpeg";
import CardItem from "./CardItem";
import { servicesList } from "./servicesList";

function ServiceOffer() {
  return (
    <>
      <div className="services-outer-wrapper">
        <CardItem isImage imgUrl={Laser_} />
        <CardItem cardColor={"#c1564c"} id={"0"} {...servicesList[0]} />
        <CardItem isImage imgUrl={Prp_face} />
        <CardItem id={"1"} {...servicesList[1]} />
        <CardItem isImage imgUrl={Prp_hair} />
        <CardItem id={"2"} {...servicesList[2]} />
        <CardItem isImage imgUrl={Gfc_face} />
        <CardItem id={"3"} {...servicesList[3]} />
        <CardItem isImage imgUrl={Gfc_hair} />
        <CardItem id={"4"} {...servicesList[4]} />
        <CardItem isImage imgUrl={Hifu} />
        <CardItem id={"5"} {...servicesList[5]} />
        <CardItem isImage imgUrl={botox} />
        <CardItem id={"6"} {...servicesList[6]} />
        <CardItem isImage imgUrl={Fillers} />
        <CardItem id={"7"} {...servicesList[7]} />
        <CardItem isImage imgUrl={hydra_facial} />
        <CardItem id={"8"} {...servicesList[8]} />
        <CardItem isImage imgUrl={Carbon_facial} />
        <CardItem id={"9"} {...servicesList[9]} />
        <CardItem isImage imgUrl={Vampire_facial} />
        <CardItem id={"10"} {...servicesList[10]} />
        <CardItem isImage imgUrl={Derma_pen} />
        <CardItem id={"11"} {...servicesList[11]} />
      </div>
    </>
  );
}

export default ServiceOffer;
