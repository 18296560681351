import React from "react";
import "./connect-card.css";
import { Button, Navbar } from "react-bootstrap";
import logo from "../assets/absolutte_logo.png";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const ConnectCard = () => {
  return (
    <div id="connect-card" className="connect-card-wrapper">
      <div className="connect-card__logo-wrapper">
        <img className="nav-logo" src={logo} alt="First slide" />
        <h1 href="#home">Absoluttee Clinic</h1>
      </div>
      <div className="connect-card__address-wrapper">
        <h3>Reach us at</h3>
        <p>
          E-84, Hansraj Gupta Rd, Block E,
          <br /> Greater Kailash I, East of Kailash,
          <br /> New Delhi, Delhi 110048
        </p>
      </div>
      <div className="connect-card__contact-wrapper">
        <h3>Contact</h3>
        <p>
          +917065070762 <br></br>+917065070761
        </p>
      </div>

      <Button className=" connect-card__booking-button">Book Now</Button>

      <div className="connect-card__social-wrapper">
        <div className="social-icon-wrapper">
          <a href="https://www.youtube.com/" className="youtube social">
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </a>
          <a href="https://www.facebook.com/" className="facebook social">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a href="https://www.twitter.com/" className="twitter social">
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a>
          <a href="https://www.instagram.com/" className="instagram social">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a href="https://www.instagram.com/" className="whatsapp social">
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
          </a>
          <a href="https://www.instagram.com/" className="email social">
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ConnectCard;
