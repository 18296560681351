import React, { useEffect, useState } from "react";
import "./card-item.css";
import { servicesList } from "./servicesList";
import ModalDescription from "./ModalDescription";

const CardItem = ({
  title,
  description,
  buttonName,
  cardColor,
  isImage = false,
  imgUrl,
  id,
  imageSpan,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(false);

  const style = {
    background: isImage ? `url(${imgUrl})` : `${"#c1564c"}`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    gridColumn: windowWidth > 900 && imageSpan ? "span 2" : "0",
  };

  useEffect(() => {
    const handleWindowResize = () => {
      // console.log(window.outerWidth);
      setWindowWidth(window.outerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleButtonClick = () => {
    setShowModal(true);
    console.log(
      servicesList[Number(id)],
      servicesList[Number(id)]?.description
    );
  };

  return (
    <>
      <div style={style} className="card-item-wrapper">
        {!isImage ? (
          <div className="card-item__content-wrapper">
            <div className="card-item__title">{title}</div>
            <div className="card-item__description">{description}</div>
            <button
              id={id}
              onClick={handleButtonClick}
              className="card-item__button"
            >
              {"Read More"}
            </button>
          </div>
        ) : null}
      </div>
      <ModalDescription
        description={servicesList[Number(id)]?.description}
        showModal={showModal}
        handleCloseModal={() => {
          setShowModal(false);
        }}
        title={servicesList[Number(id)]?.title}
      />
    </>
  );
};

export default CardItem;
