import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import emailjs from "@emailjs/browser";
import "./appointment.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AppointmentForm() {
  const form = useRef();
  const [userEmail, setUserEmail] = useState("");

  const scriptURL = 'https://script.google.com/macros/s/AKfycbxlrqTzO_K8o50Li4ndXjrAdJPSEO4_yLU6YnowreNoqO77qtyp7D_vjc2RF80Tv2MK/exec';

  const formm = document.forms['submit-to-google-sheet']

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(scriptURL, { method: 'POST', body: new FormData(formm) })
      .then(response => console.log('Success!'))
      .catch(error => console.error('Error!', error.message))

    emailjs
      .sendForm(
        "service_8ohjf68",
        "template_5973d59",
        form.current,
        "PAlyLgjERk0vVcN_9"
      )
      .then(
        (result) => {
          toast.success('Message sent successfully');
          toast.info('We will get back to you soon');
          console.log("message sent", result.text);
        },
        (error) => {
          toast.error('An error occured');
          toast.info('Please report bug');
          console.log(error.text);
        }
      );


    form.current.reset();
    setUserEmail('');
  };

  // useEffect(()=>{

  // }, [handleSubmit]);


  return (
    <div id="appointment-form" className="appointment-form-wrapper">
      <h3>Enquire Now</h3>
      <Form
        ref={form}
        onSubmit={handleSubmit}
        name="submit-to-google-sheet"
      >
        <Form.Group className="mb-3">
          {/* <Form.Label>Your Name</Form.Label> */}
          <Form.Control
            className="appointment-form__input"
            name="to_name"
            placeholder="Enter Name"
          />
        </Form.Group>
        <Form.Group type="email" className="mb-3">
          {/* <Form.Label>E-mail</Form.Label> */}
          <Form.Control
            className="appointment-form__input"
            name="to_email"
            placeholder="Enter email"
            onChange={(e) => setUserEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          {/* <Form.Label>Mobile</Form.Label> */}
          <Form.Control
            className="appointment-form__input"
            name="Phone"
            type="number"
            placeholder="Enter mobile number"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          {/* <Form.Label>Select Treatment</Form.Label> */}
          <Form.Select name="Treatment">
            <option>Select Treatment</option>
            <option>Permanent hair reduction laser</option>
            <option>PRP Face</option>
            <option>PRP Hair</option>
            <option>GFC Face</option>
            <option>GFC Hair</option>
            <option>High-intensity focused ultrasound</option>
            <option>Botox</option>
            <option>Filler</option>
            <option>Hydra facial</option>
            <option>Carbon facial</option>
            <option>Vampire facial</option>
            <option>Derma pen</option>
            <option>Microdermabrasion</option>
          </Form.Select>
        </Form.Group>
        <Button className="appointment-form__button" type="submit">
          Submit
        </Button>
      </Form>



      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default AppointmentForm;
