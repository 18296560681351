import Carousel from "react-bootstrap/Carousel";
import carasoule1 from "../assets/carasoule1.jpg";
import carasoule2 from "../assets/carasoule2.jpg";
import carasoule3 from "../assets/carasoule3.jpg";
import "./carasoule.css";
import CardItem from "./CardItem";

function Carasoule() {
  return (
    <Carousel>
      <CardItem
        title={"Permanent hair reduction laser"}
        description={
          "The treatment is one of the most commonly done painless cosmetic treatments to get rid of unwanted hair on any body part..."
        }
        buttonName={"Read More"}
        cardColor={"#c1564c"}
      />
      {/* <Carousel.Item>
        <img className="w-100 cust" src={carasoule1} alt="First slide" />
        <Carousel.Caption>
          <div className="carasoule-content">
            <h3 className="carasoule-heading">Beauty backed by science</h3>
            <p>
              Transform your skin and transform your confidence with our
              customized skin treatments
            </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="w-100 cust" src={carasoule2} alt="Second slide" />

        <Carousel.Caption>
          <h3 className="carasoule-heading">Invest in your skin today</h3>
          <p>& enhjoy a lifetime of healthy, radiant skin</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="w-100 cust" src={carasoule3} alt="Third slide" />

        <Carousel.Caption>
          <h3 className="carasoule-heading">
            Discover the power of healthy, radient skin
          </h3>
          <p>with our expert skin treatments.</p>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
  );
}

export default Carasoule;
