export const servicesList = [
  {
    title: "Permanent hair reduction laser",
    description:
      "The treatment is one of the most commonly done painless cosmetic treatments to get rid of unwanted hair on any body part. The procedure relies on the destruction of the hair follicles using light energy without even damaging the surrounding skin. It is safe, effective and virtually painless.",
    buttonName: "Read More",
    cardColor: "",
  },
  {
    title: "PRP FACE",
    description:
      "PRP is a facial rejuvenation treatment that uses your own plasma to stimulate collagen production and cell migration, allowing for the elimination of ...",
    buttonName: "Read More",
    cardColor: "",
  },
  {
    title: "PRP Hair",
    description:
      "PRP (platelet-rich plasma) therapy for hair loss is a three-step medical treatment in which a person's blood is drawn, processed, and then injected in...",
    buttonName: "Read More",
    cardColor: "",
  },
  {
    title: "GFC Face",
    description:
      "Growth Factor Concentrate (GFC) Therapy is an advanced, innovative therapy that utilises the body's inherent natural powers and capacity for self-heal...",
    buttonName: "Read More",
    cardColor: "",
  },
  {
    title: "GFC Hair",
    description:
      "Growth Factor Concentrate, or GFC, is an extremely safe treatment which uses growth factors generated from your blood for the best results in treating...",
    buttonName: "",
    cardColor: "",
  },
  {
    title: "HIFU",
    description:
      "High-intensity focused ultrasound (HIFU) is a relatively new cosmetic treatment for skin tightening that some consider a noninvasive and painless repl...",
    buttonName: "",
    cardColor: "",
  },
  {
    title: "BOTOX",
    description:
      "Botox Cosmetic is an injectable wrinkle muscle relaxer. It uses botulinum toxin type A, specifically Onabotulinumtoxin A, to temporarily paralyze musc...",
    buttonName: "",
    cardColor: "",
  },
  {
    title: "Filler",
    description:
      "They are minimally invasive procedures using fine needles to restore volume, enhance or re contour facial features and soften facial creases. Dermal f...",
    buttonName: "",
    cardColor: "",
  },
  {
    title: "Hydra facial",
    description:
      "Known for its six-step process, the HydraFacial works to deep-clean, exfoliate, and hydrate your skin. This professional procedure may help treat a va...",
    buttonName: "",
    cardColor: "",
  },
  {
    title: "Carbon facial",
    description:
      "The Carbon Peel Facial also referred to as the 'China Doll Peel Facial' (named for its porcelain doll-like finish to the skin) is an innovative laser ...",
    buttonName: "",
    cardColor: "",
  },
  {
    title: "Vampire facial",
    description:
      "Vampire facial includes injecting your own blood plasma on the face using a derma pen which boosts the skin cells and aids in collagen production. Inc...",
    buttonName: "",
    cardColor: "",
  },
  {
    title: "Derma pen",
    description:
      "This procedure is a minimally-invasive, non-surgical skin rejuvenating technique that uses the dermapen micro needling , an automated medical device t...",
    buttonName: "",
    cardColor: "",
  },
  {
    title: "MDA",
    description:
      "Microdermabrasion is a cosmetic procedure that uses tiny crystals to gently exfoliate the skin and remove dead skin. It’s often used to improve the ap...",
    buttonName: "",
    cardColor: "",
  },
];
