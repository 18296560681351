import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./carasoule.css";
import "./navbar.css";
import logo from "../assets/absolutte_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function NavbarFix() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
      className="navbar-outer-wrapper"
    >
      <Container className="navbar-container">
        <div>
          <img className="nav-logo" src={logo} alt="First slide" />
          <Navbar.Brand href="#home">AbsolutteeClinic</Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          className="navlink-container"
          id="responsive-navbar-nav"
        >
          <Nav className="navlink-wrapper me-auto">
            <Nav.Link href="#connect-card">Reach Us</Nav.Link>
            <Nav.Link href="#hero-comp">Enquire Now</Nav.Link>
            <Nav.Link
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5x",
              }}
              href="https://wa.me/+917065070762"
              target="_blank"
            >
              +917065070762{" "}
              <FontAwesomeIcon color="green" icon={faWhatsapp} size="2x" />
            </Nav.Link>
            {/* <NavDropdown title="Services" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          {/* <Nav>
            <Nav.Link href="#contact">Contact Us</Nav.Link>
            <Nav.Link eventKey={2} href="#reach">
              Reach Us
            </Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarFix;
