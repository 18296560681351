import React from "react";
import AppointmentForm from "./Appointment";
import logo from "../assets/absolutte_logo.png";
import About from "../assets/new/about-bg.png";
import "./about.css";

function AboutSection() {
  return (
    <div className="about-section" id="contact">
      <div className="about-container">
        <div className="about-text-container">
          <div className="about-primary-content">
            Introducing Absoluttee Clinic, a haven of luxury and excellence in
            the realm of dermatology, conveniently nestled in the heart of
            Delhi, GK 1. We are delighted to present a world-class destination
            that combines the latest advancements in skincare with an unwavering
            commitment to providing an unparalleled client experience.
            {/* <span className="highlighted-text-span">
              approved by the US FDA
            </span>{" "}
            to revive all of your body's senses approved modern medical
            equipment & technologies. */}
          </div>
          <div className="about-secondary-content">
            <div className="about-secondary-content__item">
              Addressing all issues with the skin and hair and delivering
              excellent outcomes for{" "}
              <span className="highlighted-text-span">
                all body, hair, and skin treatments.
              </span>
            </div>
            <div className="about-secondary-content__item">
              <span className="highlighted-text-span">
                High-quality cosmetic procedures
              </span>{" "}
              carried out by a knowledgeable group of aestheticians.
            </div>
            <div className="about-secondary-content__item">
              <span className="highlighted-text-span">
                100% customer happiness
              </span>{" "}
              is our goal, and we put a lot of effort into providing the
              greatest services to our customers.
            </div>
          </div>
        </div>
        <div className="about-image-container">
          <div>
            <img src={About} />
          </div>
          <div>
            <img src={About} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
