import React from "react";
import AppointmentForm from "./Appointment";
import temp from '../assets/logo512.png'

function Whyus() {
    return (
        <div className="centersection" id="features">
            <div className="whyusfield">
                    <div>
                        <h1>Why choose us</h1>
                    </div>
                    <div className="list-align">
                        <ul>
                        <li>Advanced solutions and treatments that rejuvenate all the senses of your body.</li>
                        <li>US FDA Approved latest medical technologies & machines.</li>
                        <li>Solving all the skin and hair-related concerns.</li>
                        <li>Providing the best results for all skin, hair and body treatments.</li>
                        <li>High quality beauty treatments performed by an expert team of aestheticians.</li>
                        <li>Believe in 100% customer satisfaction and focus on making our clients fully satisfied with best services.</li>
                        </ul>
                    </div>
            </div>
        </div>
    );
}

export default Whyus;