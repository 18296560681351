import React from "react";
import ConnectCard from "./ConnectCard";

export default function MapContainer() {
  return (
    <div className="connect-footer-wrapper" id="reach">
      <ConnectCard />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.623563778731!2d77.2430315748875!3d28.551032387778154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce31a074daa97%3A0x1fa9dfa4db8297e5!2sAbsoluttee%20Skin%20%26%20Hair%20Care%20Clinic!5e0!3m2!1sen!2sin!4v1684312293918!5m2!1sen!2sin"
        width="600"
        height="450"
        style={{ border: "0" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
