import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ModalDescription = ({ description, showModal, handleCloseModal, title }) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal}
    size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='modal-custom'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
    </Modal>
  );
};

export default ModalDescription;
