import logo from "./logo.svg";
import "./App.css";
import Carasoule from "./components/Carasoule";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarFix from "./components/Navbar";
import ServiceOffer from "./components/ServiceOffer";
import AboutSection from "./components/About";
import Whyus from "./components/WhyUs";
import CopyrightFooter from "./components/Footer";
import MapContainer from "./components/MapGoogle";
import MapReviewsContainer from "./components/GoogleReviews";
import TestimonialsSlider from "./components/ReviewSlider";
import LandingSlide from "./components/LandingSlide";
import SocialFollow from "./components/SocialFollow";
import ConnectCard from "./components/ConnectCard";

function App() {
  return (
    <div className="App">
      {/* Absolutte Clinic */}
      <NavbarFix />
      {/* <Carasoule /> */}
      <LandingSlide />
      <AboutSection />
      <SocialFollow />
      <ServiceOffer />
      {/* <Whyus /> */}
      <MapReviewsContainer />
      {/* <ConnectCard /> */}
      <MapContainer />
      <CopyrightFooter />
    </div>
  );
}

export default App;
