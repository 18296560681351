import React, { useState } from "react";
import "./TestimonialsSlider.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import testimonialsData from "./reviews.json";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

const MapReviewsContainer = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="testimonials" id="testimonials">
      <h2>Testimonials</h2>
      <div className="testimonial-slider">
        <button className="slider-btn prev-btn" onClick={handlePrev}>
          <FontAwesomeIcon icon={faArrowCircleLeft} size="2xl" />{" "}
        </button>
        <div className="testimonial" key={testimonialsData[activeIndex].id}>
          <p className="testimonial-content">
            {testimonialsData[activeIndex].testimonial}
          </p>
          <div className="testimonial-info">
            <p className="testimonial-name">
              {testimonialsData[activeIndex].name}
            </p>
            <p className="testimonial-role">
              {testimonialsData[activeIndex].role}
            </p>
            <FontAwesomeIcon icon={faGoogle} bounce />
            <div className="testimonial-rating">
              {[...Array(5)].map((_, index) => (
                <FontAwesomeIcon
                  key={index}
                  icon={faStar}
                  className={`star ${
                    index < testimonialsData[activeIndex].rating ? "filled" : ""
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
        <button className="slider-btn next-btn" onClick={handleNext}>
          <FontAwesomeIcon icon={faArrowCircleRight} size="2xl" />{" "}
        </button>
      </div>
    </div>
  );
};

export default MapReviewsContainer;
