import React from "react";
import HeroImage from "../assets/new/landing-bg.png";
import "./landing-slide.css";
import AppointmentForm from "./Appointment";
function LandingSlide() {
  return (
    <div id="hero-comp" className="hero-component-wrapper">
      <AppointmentForm />
    </div>
  );
}

export default LandingSlide;
